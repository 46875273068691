
export const months = [
    {
        displayText: 'Januari',
        value: '1'
    },
    {
        displayText: 'Februari',
        value: '2'
    },
    {
        displayText: 'Maart',
        value: '3'
    },
    {
        displayText: 'April',
        value: '4'
    },
    {
        displayText: 'Mei',
        value: '5'
    },
    {
        displayText: 'Juni',
        value: '6'
    },
    {
        displayText: 'Juli',
        value: '7'
    },
    {
        displayText: 'Augustus',
        value: '8'
    },
    {
        displayText: 'September',
        value: '9'
    },
    {
        displayText: 'Oktober',
        value: '10'
    },
    {
        displayText: 'November',
        value: '11'
    },
    {
        displayText: 'December',
        value: '12'
    }
]