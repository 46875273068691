<template>
	<div class="o-layout o-layout--large">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<recess-button :title="BUTTON_TEXT.goBack" icon="back" variant="variant4" class="qa-go-back-button" url="/result" />
					<title-text title="Resultaten" class="qa-order-title" />
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<recess-card variant="variant1">
						<div class="container-fluid">
							<div class="row">
								<div class="col-3">
									<recess-multi-select
										:selected-options="selectedFilters.currentMonth"
										:options="months"
										:single-select="true"
										:error-message="currentMonthError"
										:label-text="PROVIDER.Order.HistoricalOrderOverview.Month.Label"
										class="qa-order-details-select-month"
										@input="(newValue) => setSpecificFilter('currentMonth', newValue)"
									/>
								</div>
								<div class="col-3">
									<recess-multi-select
										:selected-options="selectedFilters.currentYear"
										:options="startYearsOptions"
										:single-select="true"
										:error-message="currentYearError"
										:label-text="PROVIDER.Order.HistoricalOrderOverview.Year.Label"
										class="qa-order-details-select-year"
										@input="(newValue) => setSpecificFilter('currentYear', newValue)"
									/>
								</div>
							</div>
						</div>
					</recess-card>
				</div>

				<order-list 
					class="col-12 mt-4" 
					:set-selected-filters="selectedFilters" 
					:is-order-overview="false"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import TitleText from '@/components/atoms/TitleText'
import OrderList from '@/components/organisms/Order/OrderList'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { months } from '@/../../shared/enums/months.js'

import filterMixin from '@/../../shared/mixins/filterMixin'

export default {
	name: 'HistoricalOrdersOverview',
	components: {
		TitleText,
		OrderList
	},
	mixins: [validationMixin, filterMixin],
	validations: {
		selectedFilters: {
			currentMonth: {
				validMonth(value, selectedFilters) {
					if (!selectedFilters.currentMonth) return true
					return months.some((validMonth) => validMonth.value === value)
				}
			},
			currentYear: {
				validYear(value, selectedFilters) {
					if (!selectedFilters.currentYear) return true
					return this.startYearsOptions.some((validYear) => validYear.value === value)
				}
			}
		}
	},
	data() {
		return {
			BUTTON_TEXT,
			PROVIDER,
			months,
			startYearsOptions: [],
			selectedFilters: {
				orderStatus: 'Invoiced',
				currentMonth: null,
				currentYear: null,
				providerId: localStorage.getItem('providerId')
			}
		}
	},
	computed: {
		...mapState('providerModule', ['selectedProvider']),
		currentMonthError() {
			if (this.$v.selectedFilters.currentMonth.$invalid && !this.$v.selectedFilters.currentMonth.validMonth) {
				return PROVIDER.Order.HistoricalOrderOverview.ErrorMessages.InvalidMonth
			}

			return null
		},
		currentYearError() {
			if (this.$v.selectedFilters.currentYear.$invalid && !this.$v.selectedFilters.currentYear.validyear) {
				return PROVIDER.Order.HistoricalOrderOverview.ErrorMessages.InvalidYear
			}
			return null
		}
	},
	watch: {
		selectedProvider: {
			handler() {
				if (this.selectedProvider && this.selectedFilters && this.selectedProvider.id !== this.selectedFilters.providerId) {
					this.setSpecificFilter('providerId', this.selectedProvider.id)
				}
			},
			deep: true
		}
	},
	created() {
		this.setYearOptions()
		if (!(this.isValidMonth() && this.isValidYear())) {
			this.setInitialFilterDates()
		}
	},
	methods: {
		setYearOptions() {
			for (let index = 0; index <= 7; index += 1) {
				const newYear = `${new Date().getUTCFullYear() - index}`

				const yearObj = {
					displayText: newYear,
					value: newYear
				}

				this.startYearsOptions.push(yearObj)
			}
		},
		setInitialFilterDates() {
			const currentDate = new Date()
			this.selectedFilters.currentMonth = `${currentDate.getUTCMonth() + 1}`
			this.selectedFilters.currentYear = `${currentDate.getUTCFullYear()}`
		},
		isValidMonth() {
			return this.months.some((validMonth) => validMonth.value === this.selectedFilters.currentMonth)
		},
		isValidYear() {
			return this.startYearsOptions.some((validYear) => validYear.value === this.selectedFilters.currentYear)
		}
	}
}
</script>
